$sub-footer-height-desktop: 145px;
$header-height: 100px;

.bsd-layout {
  min-height: calc(100vh - #{$sub-footer-height-desktop} - #{$header-height});
  padding-bottom: base-spacing(3);

  .bsd-content {
    max-width: 980px;
    margin: 0 auto;
    padding: base-spacing();
    padding-top: base-spacing(3);
  }

  .bsd-dialog {
    margin-top: base-spacing(0.5);
    margin-bottom: base-spacing(2);
  }
}

.bsd-toast {
  pointer-events: none;
}

.bsd-toast--visible {
  position: relative;
  top: base-spacing(3);

  @include responsive($width-small) {
    margin-top: 0;
  }
}

@media print {
  .bsd-layout {
    margin: 0;
    padding: 0;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  #bcp-header,
  #bcp-footer {
    display: none;
  }
}
