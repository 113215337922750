.bsd-reset-confirmation {
  &-heading {
    display: flex;
    margin-bottom: $base-spacing;

    @include responsive($width-medium) {
      align-items: center;
      margin-bottom: $base-spacing * 2;
    }

    .bsd-heading {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .bsd-body-copy {
    margin-bottom: $base-spacing;
  }
}
