.bsd-create-password {
  &-input-row {
    display: flex;
    flex-direction: column;
    margin: $base-spacing 0;

    @include responsive($width-medium) {
      flex-direction: row;
    }
  }

  &-password-input {
    display: flex;

    input {
      @include responsive($width-medium) {
        width: 328px;
      }
    }

    .bsd-btn--link {
      width: 40px;
      height: 40px;
      margin: 30px 20px 0;
      text-decoration: underline;
    }
  }
}
