.bsd-email-confirmation {
  &-heading {
    display: flex;
    margin-bottom: $base-spacing;

    @include responsive($width-medium) {
      align-items: center;
      margin-bottom: $base-spacing * 2;
    }

    .bsd-heading {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  &-contact-us {
    margin-top: 20px;
  }

  &-contact-font {
    padding-left: 5px;
    font: 17px $type-sans-serif;
    letter-spacing: 0.8px;
    line-height: 1.8;
  }

  .bsd-body-copy {
    margin-bottom: $base-spacing;
  }
}
