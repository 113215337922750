.bsd-create-email {
  &-input-row {
    display: flex;
    flex-direction: column;
    margin: $base-spacing 0;

    @include responsive($width-medium) {
      flex-direction: row;
    }
  }

  &-email-input {
    display: flex;

    input {
      @include responsive($width-medium) {
        width: 328px;
      }
    }
  }

  &-captcha {
    padding-top: 10px;

    &-component-error {
      width: max-content;
      border: 2px solid $red;
    }

    &-text-error {
      margin-top: 5px;
      color: $red;
      font: 11px $type-sans-serif;
      font-weight: $font-weight-bold;
      letter-spacing: 2px;
      line-height: 1.8;
    }
  }
}
