html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $gray-haze;
}

// this is to override unified-nav style which loads dynamically after main.css
body {
  // stylelint-disable-next-line declaration-no-important
  margin-top: 0 !important;
}
